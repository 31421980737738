<script lang="ts" setup>
import type { Icon } from '~/types/Icons'

const props = defineProps<{
  text: string
  icon: Icon
}>()
</script>

<template>
  <div
    class="flex items-center gap-5 rounded-3xl border border-red-500 bg-white p-5"
  >
    <div class="rounded-full border border-red-500 bg-white p-1">
      <div
        class="flex size-16 items-center justify-center rounded-full bg-red-600"
      >
        <img :src="'/img/' + props.icon + '.svg'" class="size-12 text-white" />
      </div>
    </div>
    <p>{{ props.text }}</p>
  </div>
</template>
