<script setup lang="ts">
const ALL_CATEGORIES = -1

const currentPage = ref(1)

const { data: news } = await useLazyFetch('/api/news')

const filteredNews = computed(() => {
  if (chosenCategory.value === ALL_CATEGORIES) {
    return news.value
  }

  return (
    news.value?.filter((newsItem) => {
      return newsItem.newsCategoryId === chosenCategory.value
    }) ?? []
  )
})

const { data: newsCategories } = await useLazyFetch('/api/news-categories')

const chosenCategory = ref(ALL_CATEGORIES)
</script>

<template>
  <main>
    <UiBanner
      image="/img/grape_pozadi_zaklad.jpg"
      class="flex min-h-[550px] items-center justify-center py-32"
    >
      <div
        class="container mx-auto flex w-fit items-end justify-center max-sm:flex-col sm:items-start"
      >
        <UiBannerTitle class="col-span-2 max-w-xl !text-left">
          Buďte s námi online doma nebo v práci
        </UiBannerTitle>
      </div>
    </UiBanner>
    <section class="relative bg-[url(/img/grape_pozadi_sede.png)] bg-repeat">
      <div class="absolute top-0 h-full w-full bg-black bg-opacity-5"></div>
      <div class="container relative text-neutral-700">
        <div
          class="relative mx-auto grid w-fit justify-center gap-16 max-lg:py-16 sm:grid-cols-2 lg:-top-24 lg:grid-cols-3"
        >
          <UiCard
            icon="ikona_wifi_bez_pozadi"
            class="flex flex-col items-center gap-5 rounded-xl bg-[url(/img/grape_pozadi_sede.png)] bg-repeat p-5 pt-10"
          >
            <UiCardTitle>INTERNET</UiCardTitle>
            <div class="text-center font-semibold">
              <p>RYCHLOST AŽ 10.000 Mbps</p>
            </div>
            <NuxtLink to="/internet" class="mt-auto">
              <UiButton class="text-sm font-bold">Chci připojit</UiButton>
            </NuxtLink>
          </UiCard>
          <UiCard
            icon="ikona_tv"
            class="relative flex flex-col items-center gap-5 rounded-xl bg-[url(/img/grape_pozadi_sede.png)] bg-repeat p-5 pt-10"
          >
            <UiCardTitle>TELEVIZE</UiCardTitle>
            <div class="text-center font-semibold">
              <p>PŘETÁČENÍ 7 DNÍ ZPĚT</p>
              <p>NAHRÁVÁNÍ 10 HODIN</p>
              <p>SLEDOVÁNÍ V TELEFONU</p>
            </div>
            <NuxtLink to="/televize" class="mt-auto">
              <UiButton class="text-sm font-bold">Chci připojit</UiButton>
            </NuxtLink>
          </UiCard>
          <UiCard
            icon="ikona_telefon"
            class="relative flex flex-col items-center gap-5 rounded-xl bg-[url(/img/grape_pozadi_sede.png)] bg-repeat p-5 pt-10"
          >
            <UiCardTitle>MOBIL</UiCardTitle>
            <div class="text-center font-semibold">
              <p>NEOMEZENÉ VOLÁNÍ</p>
              <p>NEOMEZENÉ SMS</p>
              <p>INTERNET FURT</p>
            </div>
            <NuxtLink to="/mobil" class="mt-auto">
              <UiButton class="text-sm font-bold">Chci připojit</UiButton>
            </NuxtLink>
          </UiCard>
        </div>
      </div>
    </section>
    <section
      class="container flex items-center justify-around gap-10 bg-[url(/img/grape_pozadi_cervene.png)] bg-cover py-10 max-lg:flex-col"
    >
      <div>
        <img src="/img/Grapik_24_7.png" />
      </div>
      <div class="max-w-lg text-right">
        <div class="mb-10 flex flex-wrap items-center justify-center gap-5">
          <div class="size-36">
            <img class="h-full w-full" src="/img/ikona_24_7_bez_pozadi.svg" />
          </div>
          <div class="flex flex-col items-end justify-center">
            <h2 class="text-3xl font-bold text-neutral-700">
              Jsme tu pro Vás 24/7
            </h2>
            <div class="flex items-center justify-end gap-2">
              <Icon name="fa6-solid:phone" class="size-10 text-red-600" />
              <p class="whitespace-nowrap text-5xl font-bold text-red-600">
                800 911 911
              </p>
            </div>
          </div>
        </div>
        <p class="mb-10 text-2xl font-thin">
          V případě, že potřebujete naší pomoc, nám ZDARMA zavolejte na naší
          linku 800 911 911, nebo využijte náš seznam nejčastějších dotazů, kde
          můžete najít odpovědi na Vaše otázky.
        </p>
        <div class="flex justify-end gap-5">
          <NuxtLink to="/dotazy">
            <UiButton class="font-bold">Časté dotazy</UiButton>
          </NuxtLink>
          <NuxtLink href="tel:800911911">
            <UiButton
              class="flex items-center gap-2 bg-green-500 font-bold text-white"
            >
              <Icon name="fa6-solid:phone" />
              800 911 911
            </UiButton>
          </NuxtLink>
        </div>
      </div>
    </section>
    <UiBanner
      title="Spolehlivé a rychlé připojení k internetu pro Vaše každodenní potřeby"
      :image="'/img/grape_pozadi_mesto.jpeg'"
    />
    <section
      class="container bg-[url(/img/grape_pozadi_cervene.png)] bg-cover py-10"
    >
      <div>
        <h2 class="mb-10 text-center text-3xl font-medium text-neutral-700">
          Proč si zákazníci vybírají právě nás?
        </h2>
        <div class="grid items-center gap-10 xl:grid-cols-2">
          <div class="mx-auto grid w-fit items-start gap-10 sm:grid-cols-2">
            <ServicesCard
              text="Férový poskytovatel"
              icon="ikona_fer_poskytovatel_bp"
            />
            <ServicesCard
              text="Spokojený zákazník"
              icon="ikona_perf_servis_bp"
            />
            <ServicesCard text="Budoucnost" icon="ikona_budoucnost_bp" />
            <ServicesCard text="Možnosti volby" icon="ikona_moznost_volby_bp" />
            <ServicesCard
              text="Profesionální personál"
              icon="ikona_profi_personal_bp"
            />
            <ServicesCard text="Perfektní servis" icon="ikona_perf_servis_bp" />
          </div>
          <div class="mx-auto">
            <img src="/img/Grapik_telefon2.png" />
          </div>
        </div>
      </div>
    </section>
    <section
      class="relative bg-[url(/img/grape_pozadi_sede.png)] bg-repeat py-10"
    >
      <div class="absolute top-0 h-full w-full bg-black bg-opacity-5"></div>
      <div class="container relative z-10">
        <h2 class="mb-10 text-center text-3xl font-medium text-neutral-700">
          Aktuality z naší sítě
        </h2>
        <div class="mb-5 flex flex-wrap justify-center gap-5">
          <button
            class="rounded-full bg-neutral-500 px-8 py-2 text-white"
            :class="chosenCategory === ALL_CATEGORIES ? 'bg-red-600' : ''"
            @click="chosenCategory = ALL_CATEGORIES"
          >
            Vše
          </button>
          <button
            v-for="category in newsCategories"
            class="rounded-full bg-neutral-500 px-8 py-2 text-white"
            :class="chosenCategory === category.id ? 'bg-red-600' : ''"
            @click="chosenCategory = category.id"
          >
            {{ category.title }}
          </button>
        </div>
        <div class="mx-auto mb-5 flex max-w-4xl flex-col gap-5">
          <div
            v-for="newsItem in filteredNews"
            class="rounded-3xl bg-white p-5 shadow-[0_0px_10px_0px_rgba(0,0,0,0.05)]"
          >
            <p class="mb-2 font-bold">{{ newsItem.title }}</p>
            <HTMLRenderer :content="newsItem.message" class="font-extralight" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
